'use client';

import { CssBaseline } from '@mui/material';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ElevationScroll from '../components/ElevationScroll';

import LessonMessageNotification from './components/Modal/LessonMessageNotification';
import Account from './components/Shares/Account';
import Logo from './components/Shares/Logo';
import { PATH_ONBOARDING } from '@/constants/common';
import { ELEMENT_HEADER_ID } from '@/constants/domElement';
import { NOTIFICATION_IGNORE_PATHS } from '@/constants/notification';
import { TOKEN } from '@/constants/storeLocation';
import { UserRole } from '@/constants/user';
import useSocketMessageStatus from '@/hooks/zoom/useSocketMessageStatus';
import { IUser } from '@/interfaces/user';
import { useAppDispatch } from '@/store';
import { fetchMyEnrollmentAsync } from '@/store/slices/enrollmentSlice';
import { checkMe, selectUserState } from '@/store/slices/userSlice';
import { pageContainer } from '@/styles/mixin';
import { devices } from '@/styles/variables';

const HeaderContainer = styled.div`
	@media ${devices.largeLaptop} {
		padding: 20px 0;
	}
	${pageContainer('1440px')}
	padding: 20px 24px;
`;

const Nav = styled.div`
	position: sticky;
	top: 0;
	// Set z-index above MUI Grid's default z-index of 1100 but less than Drawer's default 1200 to ensure Nav stays on top
	z-index: 1150;
`;

interface HeaderContentProps {
	user: IUser | null;
}

const HeaderContent = ({ user }: HeaderContentProps) => {
	const dispatch = useAppDispatch();
	const pathname = usePathname();

	const storeUser = useSelector(selectUserState);
	const [isMobile, setIsMobile] = useState(false);

	const [MobileNav, setMobileNav] = useState<React.FC<HeaderContentProps> | null>(null);
	const [DesktopNav, setDesktopNav] = useState<React.FC<HeaderContentProps> | null>(null);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		// 初始化时检测一次宽度
		handleResize();
		// 添加窗口大小更改监听器
		window.addEventListener('resize', handleResize);
		// 清理事件监听器
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useSocketMessageStatus(user?.id);

	useEffect(() => {
		if (user || localStorage.getItem(TOKEN)) {
			dispatch(checkMe());
			dispatch(fetchMyEnrollmentAsync());
		}
	}, []);
	// 动态引入文件
	useEffect(() => {
		if (isMobile) {
			import('./components/MoblieNav/MoblieNav').then(module =>
				setMobileNav(() => module.default)
			);
		} else {
			import('./components/MenuBar/Navigation').then(module =>
				setDesktopNav(() => module.default)
			);
		}
	}, [isMobile]);

	const isRender = () => {
		if (pathname.startsWith(PATH_ONBOARDING)) {
			return false;
		}
		return true;
	};

	return (
		isRender() && (
			<>
				<CssBaseline />
				<ElevationScroll>
					<Nav className="w-full" id={ELEMENT_HEADER_ID}>
						<HeaderContainer className="flex justify-between">
							<div className="flex items-center">
								<Logo />
								{!isMobile && DesktopNav && <DesktopNav user={user || storeUser} />}
							</div>
							<div className="flex items-center">
								<Account user={user || storeUser} />
							</div>
						</HeaderContainer>

						{isMobile && MobileNav && <MobileNav user={user || storeUser} />}
					</Nav>
				</ElevationScroll>
				{user?.role === UserRole.STUDENT &&
					!NOTIFICATION_IGNORE_PATHS.some(path => pathname.includes(path)) && (
						<LessonMessageNotification />
					)}
			</>
		)
	);
};

export default HeaderContent;
