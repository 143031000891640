'use client';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';

import ThemeRegistry from './ThemeRegistry';
import GlobalMessageError from '@/components/Shares/Message/GlobalMessageError';
import GlobalMessageSuccess from '@/components/Shares/Message/GlobalMessageSuccess';
import StyledComponentsRegistry from '@/styles/registry';

const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<StyledComponentsRegistry>
			<ThemeRegistry options={{ key: 'mui' }}>
				<SnackbarProvider
					maxSnack={1}
					Components={{
						success: GlobalMessageSuccess,
						error: GlobalMessageError
					}}
					autoHideDuration={2000}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn">
						{children}
					</LocalizationProvider>
				</SnackbarProvider>
			</ThemeRegistry>
		</StyledComponentsRegistry>
	);
};

export default Providers;
