'use client';

import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import styled from 'styled-components';

import ConsultModal from '@/components/Shares/Modal/Consult';
import { PATH_ADMIN, PATH_ONBOARDING } from '@/constants/common';
import { devices } from '@/styles/variables';

const Container = styled.div`
	margin-top: 0px;
	@media ${devices.laptop} {
		margin-top: auto;
	}
`;

const Main = ({ children }: { children: React.ReactNode }) => {
	const pathname = usePathname();

	const getGlobalConsult = (): ReactNode => {
		if (
			pathname.startsWith('/user') ||
			pathname.startsWith('/study') ||
			pathname.startsWith('/account') ||
			pathname.startsWith(PATH_ADMIN) ||
			pathname.startsWith(PATH_ONBOARDING)
		) {
			return '';
		}
		return <ConsultModal />;
	};

	return (
		<Container>
			{children}
			{getGlobalConsult()}
		</Container>
	);
};

export default Main;
