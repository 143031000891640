import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	background-color: ${color.primaryGreen};
	border: solid 1px #000;
	border-radius: 1px;
	display: flex;
	font-size: 14px;
	gap: 8px;
	line-height: 1;
	margin-top: 10vh;
	padding: 12px 16px;
	width: fit-content;
`;

interface GlobalMessageSuccessProps {
	message: React.ReactNode;
}

const GlobalMessageSuccess = React.forwardRef<HTMLDivElement, GlobalMessageSuccessProps>(
	(props, ref) => (
		<Container ref={ref}>
			<Content>
				<Image
					src="/icon/message-success.svg"
					alt="success"
					unoptimized
					loader={imageLoader}
					width={14}
					height={14}
				/>
				{props.message}
			</Content>
		</Container>
	)
);

export default GlobalMessageSuccess;
