import { useScrollTrigger } from '@mui/material';
import { usePathname } from 'next/navigation';
import React from 'react';

import { PATH_ADMIN } from '@/constants/common';
import { color } from '@/styles/variables';

interface Props {
	window?: () => Window;
	children: React.ReactElement;
}

export default function ElevationScroll(props: Props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined
	});

	const pathname = usePathname();

	const getBgColor = () => {
		if (trigger || pathname.startsWith(PATH_ADMIN)) {
			return color.whiteColor;
		}
		return color.bgColor;
	};

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
		style: {
			backgroundColor: getBgColor(),
			transition: trigger ? `all 200ms ease-in-out` : undefined,
			boxShadow: 'none'
		}
	});
}
