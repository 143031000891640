import { Menu, MenuItem } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import styled from 'styled-components';

import Button, { Color } from '@/components/UI/Button';
import { UserRole } from '@/constants/user';
import { IUser } from '@/interfaces/user';
import { useAppDispatch } from '@/store';
import { signOut } from '@/store/slices/userSlice';
import imageLoader from '@/utils/loader';

const ButtonContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;
const UserContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
`;
const User = styled(Image)`
	border-radius: 50%;
`;
const UserDetail = styled.div`
	color: #000;
	display: flex;
	flex-direction: column;
	margin-left: 8px;
	margin-right: 8px;
	max-width: 52px;
	min-width: 60px;
`;
const UserName = styled.span<{ isHamburger: boolean }>`
	font-size: ${props => (props.isHamburger ? '18px' : '14px')};
	font-weight: 600;
`;
const UserID = styled.span<{ isHamburger: boolean }>`
	font-size: ${props => (props.isHamburger ? '14px' : '10px')};
	line-height: ${props => (props.isHamburger ? '2' : '1.2')};
`;

const UserMenu = styled(Menu)`
	& .MuiMenu-paper {
		background-color: #fff;
		border: solid 1px #000;
		border-radius: 1px;
		box-shadow: none;
		width: 164px;
	}
	& .MuiMenu-list {
		padding: 0 0;
		& .MuiMenuItem-root {
			background-color: transparent;
			font-size: 16px;
			min-height: fit-content;
			padding: 0 0;
		}
	}
`;

const UserMenuItem = styled(MenuItem)`
	margin-right: 10px;
`;

const buttons: {
	title: string;
	link: string;
	color: Color;
}[] = [
	{
		title: '登录',
		link: '/user/sign-in',
		color: 'white'
	},
	{
		title: '注册',
		link: '/user/sign-up',
		color: 'default'
	}
];

const UserMenuUl = styled.ul`
	background-color: #fff;
	padding-left: 0;
	padding-top: 6px;
	width: 162px;
`;

const UserMenuLi = styled.li`
	display: flex;
	height: 48px;
	padding: 15px 24px;
	&:hover {
		background-color: #f7f7f7;
	}
`;

const DropDownIcon = styled.div<{ isActive: boolean }>`
	border-bottom: 5px solid #000;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	height: 0;
	transform: ${({ isActive }) => (isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
	transition: transform 0.3s ease-in-out;
	width: 0;
`;

interface AccountProps {
	user: IUser | null;
	isHamburger?: boolean;
}

const Account = ({ isHamburger = false, user }: AccountProps) => {
	const pathname = usePathname();
	const dispatch = useAppDispatch();

	// menu
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		dispatch(signOut({}));
	};

	const handleMouseEnterContainer = (event: React.MouseEvent<HTMLElement>) => {
		const mouseTarget = event.currentTarget;
		setTimeout(() => {
			setAnchorEl(mouseTarget);
		}, 500);
	};

	const handleMouseLeaveMenu = () => {
		setAnchorEl(null);
	};

	const getLink = (link: string) => {
		if (pathname.startsWith('/event')) {
			// 活动相关页面,需要登陆回跳
			return `${link}?redirectTo=${decodeURIComponent(pathname)}`;
		}
		return link;
	};

	return (
		<div className="flex items-center">
			{!user?.id && (
				<ButtonContainer>
					{buttons.map(item => (
						<Link key={item.title} href={getLink(item.link)}>
							<Button key={item.title} color={item.color}>
								{item.title}
							</Button>
						</Link>
					))}
				</ButtonContainer>
			)}
			{user?.id && (
				<>
					{/* {!isHamburger && (
						<Message
							src="/image/account/account-message.svg"
							alt="message"
							loader={imageLoader}
							unoptimized
							width={24}
							height={24}
						/>
					)} */}
					<UserContainer onClick={handleClick} onMouseEnter={handleMouseEnterContainer}>
						<User
							src={user.avatar || '/image/account/account-default-avatar.webp'}
							alt="avatar"
							loader={imageLoader}
							unoptimized
							width={isHamburger ? 50 : 38}
							height={isHamburger ? 50 : 38}
						/>
						<UserDetail>
							<UserName isHamburger={isHamburger}>{user.firstName}</UserName>
							<UserID isHamburger={isHamburger}>
								{(user.role === UserRole.STUDENT && user.studentId) || '暂无ID'}
							</UserID>
						</UserDetail>
						{!isHamburger && <DropDownIcon isActive={Boolean(anchorEl)} />}
					</UserContainer>
					{!isHamburger && (
						<UserMenu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									mt: 1.5,
									mx: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 'calc(50% - 5px)',
										width: 10,
										height: 10,
										border: 'solid 1px #000',
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0
									}
								}
							}}
						>
							<UserMenuUl onMouseLeave={handleMouseLeaveMenu}>
								<Link href="/account">
									<UserMenuLi>
										<UserMenuItem>
											<Image
												loader={imageLoader}
												src="/icon/gear-icon.svg"
												alt="settings"
												unoptimized
												width={18}
												height={18}
											/>
										</UserMenuItem>
										<UserMenuItem>个人设置</UserMenuItem>
									</UserMenuLi>
								</Link>
								{(user.role === UserRole.ADMIN ||
									user.role === UserRole.TEACHER) && (
									<Link href="/admin/training">
										<UserMenuLi>
											<UserMenuItem>
												<Image
													loader={imageLoader}
													src="/image/common/manage.svg"
													alt="manage"
													unoptimized
													width={18}
													height={18}
												/>
											</UserMenuItem>
											<UserMenuItem>课程管理</UserMenuItem>
										</UserMenuLi>
									</Link>
								)}
								{user.sales && (
									<Link href="/admin/lead">
										<UserMenuLi>
											<UserMenuItem>
												<Image
													loader={imageLoader}
													src="/image/common/lead.svg"
													alt="settings"
													unoptimized
													width={18}
													height={18}
												/>
											</UserMenuItem>
											<UserMenuItem>线索管理</UserMenuItem>
										</UserMenuLi>
									</Link>
								)}
								<Link href="/orders">
									<UserMenuLi>
										<UserMenuItem>
											<Image
												loader={imageLoader}
												src="/icon/file-icon.svg"
												alt="file"
												unoptimized
												width={18}
												height={18}
											/>
										</UserMenuItem>
										<UserMenuItem>我的订单</UserMenuItem>
									</UserMenuLi>
								</Link>
								<Link href="/event-orders">
									<UserMenuLi>
										<UserMenuItem>
											<Image
												loader={imageLoader}
												src="/image/common/ticket.svg"
												alt="file"
												unoptimized
												width={18}
												height={18}
											/>
										</UserMenuItem>
										<UserMenuItem>我的活动</UserMenuItem>
									</UserMenuLi>
								</Link>
								<Link href="/job-interview/manage">
									<UserMenuLi>
										<UserMenuItem>
											<Image
												loader={imageLoader}
												src="/image/common/interview.svg"
												alt="file"
												unoptimized
												width={18}
												height={18}
											/>
										</UserMenuItem>
										<UserMenuItem>管理面经</UserMenuItem>
									</UserMenuLi>
								</Link>
								<UserMenuLi>
									<UserMenuItem>
										<Image
											loader={imageLoader}
											src="/icon/power-icon.svg"
											alt="power"
											unoptimized
											width={18}
											height={18}
										/>
									</UserMenuItem>
									<UserMenuItem onClick={handleLogout}>退出登录</UserMenuItem>
								</UserMenuLi>
							</UserMenuUl>
						</UserMenu>
					)}
				</>
			)}
		</div>
	);
};

export default Account;
