import Link from 'next/link';
import styled from 'styled-components';

const Container = styled.div`
	border-bottom: solid 1px #000;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 32px 0;
	p {
		font-size: 12px;
		line-height: 1.71;
		margin: 0 0;
		span {
			text-decoration: underline;
		}
	}
`;

const FooterTerms = () => {
	return (
		<Container>
			<p>
				匠人学院网站上的所有内容，包括课程材料、徽标和匠人学院网站上提供的信息，均受澳大利亚政府知识产权法的保护。严禁未经授权使用、销售、分发、复制或修改。违规行为可能会导致法律诉讼。通过访问我们的网站，您同意尊重我们的知识产权。
				JR Academy Pty Ltd
				保留所有权利，包括专利、商标和版权。任何侵权行为都将受到法律追究。
				<Link href="/terms-and-conditions">
					<span>查看用户协议</span>
				</Link>
			</p>
		</Container>
	);
};

export default FooterTerms;
