'use client';

import { createContext } from 'react';
import { Provider } from 'react-redux';

import store from '@/store';

export const Context = createContext({});

export default function StoreProvider({ children }: { children: React.ReactNode }) {
	return <Provider store={store}>{children}</Provider>;
}
