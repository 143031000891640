import { Socket, io } from 'socket.io-client';

import { getApiUrl } from '../request';

import { ESocketLessonEventName } from '@/constants/socket';
import store from '@/store';
import {
	initializeLessonMessageNotifications,
	updateLessonLiveStatus
} from '@/store/slices/socketSlice';

class MessageWebSocketManager {
	private socket: Socket;

	constructor() {
		const token = localStorage.getItem('token');
		this.socket = io(`${getApiUrl()}/messages`, {
			auth: {
				token
			}
		});
	}

	public listenToLessonLiveStatus(): void {
		this.socket.on(ESocketLessonEventName.STATUS_UPDATED, data => {
			store.dispatch(updateLessonLiveStatus(data));
		});
	}

	public initialLessonLiveStatus(): void {
		this.socket.once(ESocketLessonEventName.INITIAL_STATUS, data => {
			store.dispatch(initializeLessonMessageNotifications(data));
		});
	}

	public disconnect(): void {
		this.socket.disconnect();
	}
}

export default MessageWebSocketManager;
