import { Link } from '@mui/material';
import Image from 'next/image';

import imageLoader from '@/utils/loader';

const Logo = () => {
	return (
		<Link href="/" className="flex">
			<Image
				src="/icon/logo-zh.svg"
				alt="logo"
				unoptimized
				loader={imageLoader}
				width={116}
				height={30}
			/>
		</Link>
	);
};

export default Logo;
