'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import FooterCopyright from './FooterCopyright';
import FooterDisclaimer from './FooterDisclaimer';
import FooterHeader from './FooterHeader';
import FooterTerms from './FooterTerms';
import { PATH_ADMIN, PATH_ONBOARDING } from '@/constants/common';
import { color, devices } from '@/styles/variables';

const Container = styled.div`
	background-color: ${color.bgColor};
	border-top: 1px solid ${color.blackColor};
`;
const FooterContent = styled.div`
	background-color: ${color.bgColor};
	margin: 0 auto;
	max-width: 1440px;
	padding: 30px 0;
`;
const FooterSection = styled.div`
	@media ${devices.largeLaptop} {
		padding: 0 0;
	}
	padding: 0 24px;
`;

const LinksFooterSection = styled.div`
	border-bottom: solid 1px #000;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 32px 0;
	row-gap: 30px;
	@media ${devices.laptop} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
`;

const LinkSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: fit-content;
	p {
		margin: 0 0;
	}
`;
const LinkHeader = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
`;
const LinkItem = styled(Link)`
	color: inherit;
	font-size: 12px;
	line-height: 12px;
	text-decoration: none;
	@media ${devices.laptop} {
		font-size: 14px;
		line-height: 14px;
	}
`;

const Address = styled.address`
	color: inherit;
	font-size: 12px;
	line-height: 12px;
	text-decoration: none;
	@media ${devices.laptop} {
		font-size: 14px;
		line-height: 14px;
	}
`;

const Footer = () => {
	const pathname = usePathname();

	const isRender = () => {
		if (pathname.startsWith(PATH_ADMIN) || pathname.startsWith(PATH_ONBOARDING)) {
			return false;
		}
		return true;
	};
	return isRender() ? (
		<Container>
			<FooterContent>
				<FooterSection>
					<FooterHeader />
				</FooterSection>
				<FooterSection>
					<LinksFooterSection>
						<LinkSection>
							<LinkHeader>关于公司</LinkHeader>
							<LinkItem href="/about">关于我们</LinkItem>
							<LinkItem href="/metabox">元宇宙课堂</LinkItem>
							<LinkItem href="/blog">新闻资讯</LinkItem>
							<LinkItem href="https://jobpin.com.au/companies/62ecb6f0c9c36a41bdb12b42?&pageNumber=1&perPage=8">
								匠人工作
							</LinkItem>
							<LinkItem href="https://forms.zohopublic.com/JRAcademy/form/Tutor/formperma/qgqAp726xEgsZG9EFaHaxw3pUXgsH07ETLxuTXc0y4A">
								成为导师
							</LinkItem>
							<LinkItem href="/instructors">匠人导师</LinkItem>
							<LinkItem href="/contact">联系我们</LinkItem>
						</LinkSection>
						<LinkSection>
							<LinkHeader>匠人服务</LinkHeader>
							<LinkItem href="/job">工作内推</LinkItem>
							<LinkItem href="#">匠人伙伴计划</LinkItem>
							<LinkItem href="#">企业培训</LinkItem>
							<LinkItem href="/study">线上学习平台</LinkItem>
							<LinkItem href="#">Internship</LinkItem>
						</LinkSection>
						<LinkSection>
							<LinkHeader>大学资源</LinkHeader>
							<LinkItem href="/university/university-of-melbourne">
								墨尔本大学
							</LinkItem>
							<LinkItem href="/university/university-of-queensland">
								昆士兰大学
							</LinkItem>
							<LinkItem href="/university/university-of-new-south-wales">
								新南威尔士大学
							</LinkItem>
							<LinkItem href="/university/university-of-sydney">悉尼大学</LinkItem>
							<LinkItem href="/university/monash-university">莫那什大学</LinkItem>
							<LinkItem href="/university/university-of-adelaide">
								阿德莱德大学
							</LinkItem>
							<LinkItem href="/university/rmit-university">RMIT</LinkItem>
							<LinkItem href="/university/queensland-university-of-technology">
								QUT
							</LinkItem>
							<LinkItem href="/university/university-of-technology-sydney">
								UTS
							</LinkItem>
						</LinkSection>
						<LinkSection>
							<LinkHeader>企业合作</LinkHeader>
							<LinkItem href="#">实习合作</LinkItem>
							<LinkItem href="#">招聘合作</LinkItem>
							<LinkItem href="#">申请合作</LinkItem>
						</LinkSection>
						<LinkSection>
							<LinkHeader>匠人支持</LinkHeader>
							<LinkItem href="#">FAQs</LinkItem>
							<LinkItem href="/terms-and-conditions">Terms & Conditions</LinkItem>
							<LinkItem href="/privacy-policy">Privacy Policy</LinkItem>
							<LinkItem href="/cancellation-refund-policy">
								Cancellation & Refund Policy
							</LinkItem>
							<LinkItem href="/sitemap.xml">Site map</LinkItem>
						</LinkSection>
					</LinksFooterSection>
				</FooterSection>
				<FooterSection>
					<LinksFooterSection>
						<LinkSection>
							<LinkHeader>Top Categories</LinkHeader>
							<LinkItem href="/program-course/web-code-bootcamp-or-learn-to-code-1">
								Web全栈班
							</LinkItem>
							<LinkItem href="/program-course/devops">DevOps项目班</LinkItem>
							<LinkItem href="/program-course/data-engineer-data-analyst">
								数据工程全栈班
							</LinkItem>
							<LinkItem href="/program-course/data-analyst-power-bi-python-excel">
								数据分析项目班
							</LinkItem>
							<LinkItem href="/program-course/web-code-bootcamp-or-learn-to-code">
								编程入门班
							</LinkItem>
							<LinkItem href="/program-course/business-analyst">
								Business Analyst实习
							</LinkItem>
							<LinkItem href="/program-course/algorithm-bootcamp">
								算法集训营
							</LinkItem>
						</LinkSection>
						<LinkSection>
							<LinkHeader>求职就业</LinkHeader>
							<LinkItem href="/career-coaching/business-analyst-internship">
								BA和产品经理实习
							</LinkItem>
							<LinkItem href="/career-coaching/dj-consulting-sydney-data-science-intern">
								数据科学实习
							</LinkItem>
							<LinkItem href="/career-coaching/vmor-data-analyst-program-internship">
								数据分析实习
							</LinkItem>
							<LinkItem href="/career-coaching/digital-marketing">
								Marketing实习
							</LinkItem>
							<LinkItem href="/career-coaching/resume-package">简历修改</LinkItem>
							<LinkItem href="/career-coaching/australia-interview-training">
								面试指导
							</LinkItem>
							<LinkItem href="/career-coaching">导师指导VIP</LinkItem>
						</LinkSection>
						<LinkSection
							style={{
								gridColumn: 'span 2'
							}}
						>
							<LinkHeader>地址</LinkHeader>
							<Address>
								Level 10b, 144 Edward Street, Brisbane CBD(Headquarter)
							</Address>
							<Address>Level 2, 171 La Trobe St, Melbourne VIC 3000</Address>
							<Address>
								四川省成都市武侯区桂溪街道天府大道中段500号D5东方希望天祥广场B座45A13号
							</Address>
							<Address>Business Hub, 155 Waymouth St, Adelaide SA 5000</Address>
						</LinkSection>
						<LinkSection>
							<LinkHeader>联系方式</LinkHeader>
							<LinkItem href="mailto:hello@jiangren.com.au">
								hello@jiangren.com.au
							</LinkItem>
							<LinkItem href="tel:+61421672555">0421-672-555</LinkItem>
						</LinkSection>
					</LinksFooterSection>
				</FooterSection>
				<FooterSection>
					<FooterDisclaimer />
				</FooterSection>
				<FooterSection>
					<FooterTerms />
				</FooterSection>
				<FooterSection>
					<FooterCopyright />
				</FooterSection>
			</FooterContent>
		</Container>
	) : (
		''
	);
};

export default Footer;
