import { notification } from 'antd';
import { isEmpty } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { selectLessonMessageNotifications } from '@/store/slices/socketSlice';
import { color } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const LessonMessageContent = styled.div``;
const CloseButton = styled.div`
	cursor: pointer;
	padding: 18px;
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	gap: 6px;
`;
const Title = styled.h3`
	font-weight: 600;
	margin: 0;
`;
const Content = styled.div`
	margin-top: 12px;
`;
const ContentSubtitle = styled.h5`
	color: ${color.textColorDark};
	font-size: 14px;
	font-weight: normal;
	margin: 0;
`;
const ContentTitle = styled.h4`
	margin: 0;
`;
const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
`;
const NotificationContainer = styled.div`
	.ant-notification-notice-close {
		position: absolute;
		right: 0;
		top: 0;
	}
`;

const LessonMessageNotification = () => {
	const lessonMessageNotifications = useSelector(selectLessonMessageNotifications);
	const [activeNotifications, setActiveNotifications] = useState<string[]>([]);

	const [api, contextHolder] = notification.useNotification({
		top: 100,
		duration: 0
	});

	// Listen to new notification
	useEffect(() => {
		if (!isEmpty(lessonMessageNotifications)) {
			lessonMessageNotifications.forEach(lessonMessageNotification => {
				const notificationKey = lessonMessageNotification.id;
				// if the notification is already open, and the lesson is not live, then close it
				if (activeNotifications.includes(notificationKey)) {
					if (!lessonMessageNotification.isLive) {
						api.destroy(notificationKey);
					}
					// if the notification is already open, and the lesson is live, then do nothing
					return;
				}

				api.open({
					key: notificationKey,
					message: (
						<LessonMessageContent>
							<Header>
								<Image
									src="/icon/loudspeaker.svg"
									alt="loudspeaker-icon"
									width={24}
									height={18}
									unoptimized
									loader={imageLoader}
								/>
								<Title>课程直播正在进行中！</Title>
							</Header>
							<Content>
								<ContentSubtitle>
									{lessonMessageNotification.programName}
								</ContentSubtitle>
								<ContentTitle>{lessonMessageNotification.lessonName}</ContentTitle>
							</Content>
							<Footer>
								<Link
									href={`/zoom/classroom/${lessonMessageNotification.programId}/${lessonMessageNotification.id}`}
									target="_blank"
								>
									<Button>进入直播</Button>
								</Link>
							</Footer>
						</LessonMessageContent>
					),
					closeIcon: (
						<CloseButton>
							<Image
								src="/icon/close.svg"
								alt="close"
								width={11}
								height={11}
								unoptimized
								loader={imageLoader}
							/>
						</CloseButton>
					),
					style: {
						borderRadius: '0',
						border: '1px solid #000',
						position: 'relative',
						padding: '20px 0 16px 20px'
					},
					onClose: () => {
						setActiveNotifications(prev => prev.filter(key => key !== notificationKey));
					}
				});

				setActiveNotifications([...activeNotifications, notificationKey]);
			});
		}
	}, [lessonMessageNotifications]);

	return <NotificationContainer>{contextHolder}</NotificationContainer>;
};

export default LessonMessageNotification;
