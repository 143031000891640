import Image from 'next/image';
import styled from 'styled-components';

import { devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	border-bottom: solid 1px #000;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 32px 0;
	p {
		margin: 0 0;
	}
`;
const Title = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
`;
const ContentContainer = styled.div`
	align-items: start;
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media ${devices.laptop} {
		align-items: center;
		flex-direction: row;
	}
`;
const ContentImagesContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 24px;
`;
const Content = styled.p`
	font-size: 12px;
	line-height: 1.71;
`;

const FooterDisclaimer = () => {
	return (
		<Container>
			<Title>Disclaimer</Title>
			<ContentContainer>
				<ContentImagesContainer>
					<Image
						src="/image/layout/footer-disclaimer-one.webp"
						alt="footer-disclaimer"
						width={66}
						height={46}
						unoptimized
						loader={imageLoader}
					/>
					<Image
						src="/image/layout/footer-disclaimer-two.webp"
						alt="footer-disclaimer"
						width={66}
						height={46}
						unoptimized
						loader={imageLoader}
					/>
				</ContentImagesContainer>
				<Content>
					JR Academy acknowledges Traditional Owners of Country throughout Australia and
					recognises the continuing connection to lands, waters and communities. We pay
					our respect to Aboriginal and Torres Strait Islander cultures; and to Elders
					past and present. Aboriginal and Torres Strait Islander peoples should be aware
					that this website may contain images or names of people who have since passed
					away.
				</Content>
			</ContentContainer>
		</Container>
	);
};

export default FooterDisclaimer;
